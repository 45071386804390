import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LogoutIcon from '@mui/icons-material/Logout';
import Web3 from 'web3';
import AT3Balance from '../../Commerce/AT3Balance';
import StakedBalance from '../../Staking/StakedBalance';
import AT3ToUSDTPrice from '../../Commerce/MarketPrice';
import AlertModal from '../../AlertModal';
import ConnectWallet from '../../ConnectWallet';

interface HeaderProps {
  account: string | null;
  setAccount: (account: string | null) => void;
}

const Header: React.FC<HeaderProps> = ({ account, setAccount }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const showAlert = (severity: 'success' | 'error' | 'warning' | 'info', message: string) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        showAlert('success', '¡Wallet conectada con éxito!');
      } catch (error) {
        console.error('Error al conectar MetaMask', error);
        showAlert('error', 'Error al conectar MetaMask');
      }
    } else {
      showAlert('warning', 'MetaMask no está instalado. Por favor, instálalo para continuar.');
    }
  };

  const handleLogout = () => {
    setAccount(null);
    showAlert('info', 'Wallet desconectada con éxito.');
  };

  useEffect(() => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      web3.eth.getAccounts().then((accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        }
      });
    }
  }, [setAccount]);

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#051230', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ display: { xs: 'block', md: 'none' }, color: 'white' }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" sx={{ flexGrow: 1, color: 'white' }}></Typography>
          
              
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 2 }}>
            {account && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', color: 'white', gap: 0.5 }}>
                  <WalletIcon fontSize="small" />
                  <AT3Balance account={account} />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', color: 'white', gap: 0.5 }}>
                  <LockIcon fontSize="small" />
                  <StakedBalance account={account} />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', color: 'white', gap: 0.5 }}>
                  <AttachMoneyIcon fontSize="small" />
                  <AT3ToUSDTPrice />
                </Box>
                <ConnectWallet onConnect={(connectedAccount) => setAccount(connectedAccount)} />
                {/* <IconButton sx={{ color: 'white' }} onClick={handleLogout}>
                  <LogoutIcon fontSize="small" />
                </IconButton> */}
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Menú lateral (Drawer) para pantallas pequeñas */}
      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List>
          {account && (
            <>
              <ListItem>
                <WalletIcon fontSize="small" />
                <AT3Balance account={account} />
              </ListItem>

              <ListItem>
                <LockIcon fontSize="small" />
                <StakedBalance account={account} />
              </ListItem>

              <ListItem>
                <AttachMoneyIcon fontSize="small" />
                <AT3ToUSDTPrice />
              </ListItem>
{/* 
              <ListItem button onClick={handleLogout}>
                <LogoutIcon fontSize="small" />
                <ListItemText primary="Logout" />
              </ListItem> */}
            </>
          )}
        </List>
      </Drawer>

      <AlertModal open={openAlert} severity={alertSeverity} message={alertMessage} onClose={() => setOpenAlert(false)} />
    </>
  );
};

export default Header;
