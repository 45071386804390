import React from 'react';
import { Box, Typography, Link, Grid, TextField, Button } from '@mui/material';
import logo from '../../../assets/images/logo.png';

const Footer: React.FC = () => {
  return (
    <Box 
      sx={{ 
        backgroundColor: '#051230', 
        color: 'white', 
        p: 4, 
        borderTop: '1px solid #7d90ff63', 
        display: 'flex', 
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          maxWidth: '80%',
          width: '100%',
          borderRadius: '50px',
          p: 4,
        }}
      >
        <Grid container spacing={4} justifyContent="center">
          {/* Logo Section */}
          <Grid item xs={12} sm={4} md={3} sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <img src={logo} alt="Logo" style={{ height: 150 }} />
            </Box>
          </Grid>

          {/* Legal Section */}
          <Grid item xs={12} sm={4} md={3}>
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <Typography variant="body2">
              <Link href="/terms" color="inherit" underline="hover">Términos y Condiciones</Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/privacy" color="inherit" underline="hover">Política de privacidad</Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/contact" color="inherit" underline="hover">Contacto</Link>
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              WhatsApp: +595 992 544500
            </Typography>
            <Typography variant="body2">
              Mail: <Link href="mailto:info@atomico3.io" color="inherit" underline="hover">info@atomico3.io</Link>
            </Typography>
            <Typography variant="body2">
              Ubicación: Av. Santa Teresa y Coronel Escurra - Ycua Sati. Asunción, Paraguay
            </Typography>
          </Grid>

          {/* Soporte Section */}
          <Grid item xs={12} sm={4} md={3}>
            <Typography variant="h6" gutterBottom>
              Soporte
            </Typography>
            <Typography variant="body2">
              Email: <Link href="mailto:support@atomico3.io" color="inherit" underline="hover">support@atomico3.io</Link>
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              WhatsApp: +595 992 544500
            </Typography>
          </Grid>

          {/* Compliance Section with Newsletter */}
          <Grid item xs={12} sm={4} md={3}>
            <Typography variant="h6" gutterBottom>
              Compliance
            </Typography>
            <Typography variant="body2" gutterBottom>
              Email: <Link href="mailto:compliance@atomico3.io" color="inherit" underline="hover">compliance@atomico3.io</Link>
            </Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>
              Suscríbete a nuestro newsletter
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <TextField 
                variant="outlined" 
                placeholder="Tu correo" 
                size="small" 
                sx={{ backgroundColor: 'white', borderRadius: 1, flex: 1 }}
              />
              <Button variant="contained" color="primary" sx={{ borderRadius: 1 }}>
                Suscribirse
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Typography variant="caption" color="inherit">
            © {new Date().getFullYear()} Atomico3. Todos los derechos reservados.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
