import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ConfirmAccount: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null); // null para estado inicial
  const [errorMessage, setErrorMessage] = useState('');

  // Obtener parámetros de la URL
  const token = searchParams.get('token');
  const type = searchParams.get('type');

  // Validar parámetros
  useEffect(() => {
    if (!token || !type || (type !== 'user' && type !== 'company')) {
      setErrorMessage('Parámetros inválidos. Verifica el enlace de confirmación.');
      setSuccess(false);
    }
  }, [token, type]);

  const handleConfirmAccount = async () => {
    if (!token || !type) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://app.atomico3.io/api/v1/confirm_account?token=${token}&type=${type}`,
        { method: 'GET' }
      );

      if (response.ok) {
        setSuccess(true);
      } else {
        const error = await response.json();
        setErrorMessage(error.message || 'Error al confirmar la cuenta.');
        setSuccess(false);
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al procesar tu solicitud.');
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        px: 2,
      }}
    >
      {success === null && (
        <>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Confirmación de Cuenta
          </Typography>
          <Typography variant="body1" sx={{ mb: 5 }}>
            Haz clic en el botón para confirmar tu cuenta.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={loading || !token || !type}
            onClick={handleConfirmAccount}
          >
            {loading ? 'Confirmando...' : 'Confirmar Cuenta'}
          </Button>
        </>
      )}

      {/* Modal de Éxito */}
      {success === true && (
        <Dialog open={true} onClose={() => setSuccess(null)}>
          <DialogContent sx={{ textAlign: 'center', p: 4 }}>
            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 80,
                height: 80,
                borderRadius: '50%',
                backgroundColor: '#e6f7e6',
                mb: 2,
                mx: 'auto',
              }}
            >
              <CheckCircleIcon sx={{ color: '#2e7d32', fontSize: 50 }} />
            </Box>
            <Typography variant="h5" sx={{ color: '#2e7d32', mb: 2 }}>
              ¡Cuenta confirmada!
            </Typography>
            <Typography variant="body1" sx={{ color: '#4caf50' }}>
              Tu cuenta ha sido confirmada exitosamente. Ya puedes iniciar sesión.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSuccess(null)}
              sx={{
                color: '#2e7d32',
                fontWeight: 'bold',
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Modal de Error */}
      {success === false && (
        <Dialog open={true} onClose={() => setSuccess(null)}>
          <DialogContent sx={{ textAlign: 'center', p: 4 }}>
            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 80,
                height: 80,
                borderRadius: '50%',
                backgroundColor: '#ffe6e6',
                mb: 2,
                mx: 'auto',
              }}
            >
              <ErrorOutlineIcon sx={{ color: '#cc0000', fontSize: 50 }} />
            </Box>
            <Typography variant="h5" sx={{ color: '#cc0000', mb: 2 }}>
              Error al confirmar
            </Typography>
            <Typography variant="body1" sx={{ color: '#cc0000' }}>
              {errorMessage || 'No se pudo confirmar la cuenta. Intenta nuevamente.'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSuccess(null)}
              sx={{
                color: '#cc0000',
                fontWeight: 'bold',
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ConfirmAccount;
