import React from 'react';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface CompanyFormProps {
  formData: any;
  showPassword: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleShowPassword: () => void;
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  formData,
  showPassword,
  handleChange,
  toggleShowPassword,
}) => (
  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
    <Typography variant="h6" gutterBottom>
      Datos de la Empresa
    </Typography>
    <TextField
      label="País de Constitución"
      name="country"
      value={formData.country}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
    <TextField
      label="Domicilio Fiscal"
      name="fiscal_address"
      value={formData.fiscal_address}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
    <TextField
      label="Razón Social"
      name="company_name"
      value={formData.company_name}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
    <TextField
      label="Representante Legal"
      name="legal_representative"
      value={formData.legal_representative}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
    <TextField
      label="Nro de Identificación Tributaria"
      name="tax_id"
      value={formData.tax_id}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
    <TextField
      label="Nro de Teléfono"
      name="phone_number"
      value={formData.phone_number}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
    <TextField
      label="Correo Electrónico"
      name="email"
      value={formData.email}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
    <TextField
      label="Contraseña"
      name="password"
      type={showPassword ? 'text' : 'password'}
      value={formData.password}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    <TextField
      label="Repetir Contraseña"
      name="confirmPassword"
      type="password"
      value={formData.confirmPassword}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
  </Box>
);

export default CompanyForm;
