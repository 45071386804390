import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import StakeForm from '../../components/Staking/StakeForm';
import UnstakeForm from '../../components/Staking/UnstakeForm';
import linesImage from '../../assets/images/lineas.png';
import BuyAT3 from '../../components/Commerce/buyAT3';
import PeerToPeer from '../../components/Commerce/peertopeer';
import GetStakeForm from '../../components/Staking/GetStakeForm';
import Sidebar from '../../components/Layout/Sidebar/Sidebar';
import AT3PriceChart from '../../components/AT3PriceChart';


interface HomeProps {
  account: string | null;
  setAccount: (account: string | null) => void;
}

const Home: React.FC<HomeProps> = ({ account, setAccount }) => {
  const [activeComponent, setActiveComponent] = useState<string | null>(null);

  if (!account) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="white">
          Por favor, conecta tu wallet para continuar.
        </Typography>
      </Box>
    );
  }

  const renderComponent = () => {
    switch (activeComponent) {
      case 'stake':
        return <StakeForm />;
      case 'unstake':
        return <UnstakeForm />;
      case 'viewStake':
        return <GetStakeForm />;
      case 'peerToPeer':
        return <PeerToPeer />;
      case 'buyAT3':
        return <BuyAT3 />;
      case 'viewPriceChart':
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: 800,
              height: 500,
              border: '1px solid #ffffff33',
              borderRadius: 4,
              overflow: 'hidden',
            }}
          >
            <AT3PriceChart />
          </Box>
        );
      default:
        return <Typography variant="h4" color="white">Bienvenido a Atomico3</Typography>
        ;
        
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        ml: '250px',
        backgroundImage: `url(${linesImage}), linear-gradient(45deg, #051230, #051230)`,
        backgroundBlendMode: 'saturation',
        backgroundSize: 'cover, 100% 200%',
        backgroundPosition: 'bottom, 0% 50%',
        backgroundRepeat: 'no-repeat, no-repeat',
        animation: 'gradient 45s ease infinite',
        '@keyframes gradient': {
          '0%': { backgroundPosition: '0% 50%' },
          '50%': { backgroundPosition: '100% 50%' },
          '100%': { backgroundPosition: '0% 50%' },
        },
      }}
    >
      {/* Sidebar */}
      <Sidebar account={account} setAccount={setAccount} setActiveComponent={setActiveComponent} />

      {/* Contenido principal */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {renderComponent()}
      </Box>
    </Box>
  );
};

export default Home;