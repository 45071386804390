// PrivateAuth.tsx
import React, { useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

interface PrivateAuthProps {
  isAuthenticated: boolean;
  redirectToLogin: string;
}

const PrivateAuth: React.FC<PrivateAuthProps> = ({ isAuthenticated, redirectToLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      // Redirige a la página de inicio de sesión y almacena la ruta deseada
      navigate(redirectToLogin, { state: { from: location } });
    }
  }, [isAuthenticated, navigate, location, redirectToLogin]);

  return isAuthenticated ? <Outlet /> : null;
};

export default PrivateAuth;
