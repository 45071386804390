import React from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import useRegister from '../hooks/useRegister';
import IndividualForm from './IndividualForm';
import CompanyForm from './CompanyForm';

const Register: React.FC = () => {
  const {
    isCompany,
    step,
    formData,
    showPassword,
    successModalOpen,
    errorModalOpen,
    passwordValidation,
    handleChange,
    toggleShowPassword,
    handleNextStep,
    handlePrevStep,
    passwordsMatch,
    handleSelectType,
    handleSubmit,
    handleCloseSuccessModal,
    handleCloseErrorModal,
  } = useRegister();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 700 }}>
      {step === 1 && (
        <>
          <Typography variant="h6" gutterBottom>
            ¿Eres una Persona Juridica o una Persona Física?
          </Typography>
          <Button variant="outlined" onClick={() => handleSelectType('company')} sx={{ mb: 2 }}>
          Persona Juridica
          </Button>
          <Button variant="outlined" onClick={() => handleSelectType('person')} sx={{ mb: 2 }}>
            Persona Física
          </Button>
        </>
      )}

      {isCompany && step > 1 && (
        <>
          <CompanyForm
            formData={formData}
            showPassword={showPassword}
            handleChange={handleChange}
            toggleShowPassword={toggleShowPassword}
          />
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 3 }} onClick={handleSubmit}>
            Enviar
          </Button>
        </>
      )}

      {!isCompany && step > 1 && (
        <>
          <IndividualForm
            formData={formData}
            showPassword={showPassword}
            handleChange={handleChange}
            toggleShowPassword={toggleShowPassword}
          />
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 3 }} onClick={handleSubmit}>
            Enviar
          </Button>
        </>
      )}

      {/* Modal de Error */}
      <Dialog open={errorModalOpen} onClose={handleCloseErrorModal} maxWidth="sm" fullWidth>
  <DialogContent sx={{ textAlign: 'center', p: 4 }}>
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        height: 80,
        borderRadius: '50%',
        backgroundColor: '#ffe6e6',
        mb: 2,
        mx: 'auto',
      }}
    >
      <ErrorOutlineIcon sx={{ color: '#cc0000', fontSize: 50 }} />
    </Box>
    <Typography variant="h5" sx={{ color: '#cc0000', mb: 2 }}>
      Error en la contraseña
    </Typography>
    <List>
      <ListItem>
        <ListItemIcon>
          {passwordValidation.length ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon sx={{ color: '#cc0000' }} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Debe tener al menos 8 caracteres"
          primaryTypographyProps={{
            sx: { color: passwordValidation.length ? 'green' : '#cc0000' },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          {passwordValidation.uppercase ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon sx={{ color: '#cc0000' }} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Debe incluir al menos una letra mayúscula"
          primaryTypographyProps={{
            sx: { color: passwordValidation.uppercase ? 'green' : '#cc0000' },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          {passwordValidation.lowercase ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon sx={{ color: '#cc0000' }} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Debe incluir al menos una letra minúscula"
          primaryTypographyProps={{
            sx: { color: passwordValidation.lowercase ? 'green' : '#cc0000' },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          {passwordValidation.number ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon sx={{ color: '#cc0000' }} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Debe incluir al menos un número"
          primaryTypographyProps={{
            sx: { color: passwordValidation.number ? 'green' : '#cc0000' },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          {passwordValidation.specialChar ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon sx={{ color: '#cc0000' }} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Debe incluir al menos un carácter especial (@$!%*?&#)"
          primaryTypographyProps={{
            sx: { color: passwordValidation.specialChar ? 'green' : '#cc0000' },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          {passwordsMatch ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon sx={{ color: '#cc0000' }} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Las contraseñas deben coincidir"
          primaryTypographyProps={{
            sx: { color: passwordsMatch ? 'green' : '#cc0000' },
          }}
        />
      </ListItem>
    </List>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseErrorModal} sx={{ color: '#cc0000' }}>
      Cerrar
    </Button>
  </DialogActions>
</Dialog>

      {/* Modal de Éxito */}
      <Dialog open={successModalOpen} onClose={handleCloseSuccessModal} maxWidth="sm" fullWidth>
  <DialogContent sx={{ textAlign: 'center', p: 4 }}>
    {/* Ícono centrado */}
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        height: 80,
        borderRadius: '50%',
        backgroundColor: '#e6f7e6', // Fondo verde suave
        mb: 2,
        mx: 'auto',
      }}
    >
      <CheckCircleIcon sx={{ color: '#2e7d32', fontSize: 50 }} /> {/* Verde más neutro */}
    </Box>

    {/* Título */}
    <Typography variant="h5" sx={{ color: '#2e7d32', mb: 2 }}>
      ¡Registro Exitoso!
    </Typography>

    {/* Descripción */}
    <Typography variant="body1" sx={{ color: '#2e7d32' }}>
      Revisa tu correo para confirmar tu cuenta y completar el registro.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button
      onClick={handleCloseSuccessModal}
      sx={{
        color: '#2e7d32',
        fontWeight: 'bold',
      }}
    >
      Cerrar
    </Button>
  </DialogActions>
</Dialog>

    </Box>
  );
};

export default Register;
