// App.tsx
import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './theme';
import Header from './components/Layout/Header/Header';
import Footer from './components/Layout/Footer/Footer';
import MobileAlert from './components/Layout/Mobile/Mobile';
import RouteManager from './components/PrivateContent/RouteManager';

const App: React.FC = () => {
  const [account, setAccount] = useState<string | null>(null);

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
      setAccount(sessionToken);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: '#0b1b42',
            margin: 0,
            padding: 0,
            minHeight: '100vh',
            boxSizing: 'border-box',
          },
          '.fade-enter': {
            opacity: 0,
            transform: 'scale(0.95)',
          },
          '.fade-enter-active': {
            opacity: 1,
            transform: 'scale(1)',
            transition: 'opacity 300ms, transform 300ms',
          },
          '.fade-exit': {
            opacity: 1,
            transform: 'scale(1)',
          },
          '.fade-exit-active': {
            opacity: 0,
            transform: 'scale(0.95)',
            transition: 'opacity 300ms, transform 300ms',
          },
        }}
      />
      <Router>
        <Header account={account} setAccount={setAccount} />
        <MobileAlert />
        <RouteManager account={account} setAccount={setAccount} />
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
