import React from 'react';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface IndividualFormProps {
  formData: any;
  showPassword: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleShowPassword: () => void;
}

const IndividualForm: React.FC<IndividualFormProps> = ({
  formData,
  showPassword,
  handleChange,
  toggleShowPassword,
}) => (
  <Box>
     <Typography variant="h6" gutterBottom>
        Persona Física
        </Typography>
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
       
        
        <TextField
        label="Nombre"
        name="first_name"
        value={formData.first_name}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        />
        <TextField
        label="Apellido"
        name="last_name"
        value={formData.last_name}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        />
        <TextField
        label="Correo Electrónico"
        name="email"
        value={formData.email}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        />
       
    </Box>

    <Box sx={{p:2}}>
        {/* Aqui poner un divisor */}
    </Box>



    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
    
        <TextField
        label="Contraseña"
        name="password"
        type={showPassword ? 'text' : 'password'}
        value={formData.password}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        InputProps={{
            endAdornment: (
            <InputAdornment position="end">
                <IconButton onClick={toggleShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
            ),
        }}
        />
        <TextField
        label="Repetir Contraseña"
        name="confirmPassword"
        type="password"
        value={formData.confirmPassword}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        />
        <TextField
        label="URL Documento de Identidad"
        name="identity_document_url"
        value={formData.identity_document_url}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        />
    </Box>
  </Box>
);

export default IndividualForm;
