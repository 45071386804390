import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Registrar componentes de Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AT3PriceChart: React.FC = () => {
  const [priceData, setPriceData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [price, setPrice] = useState<number | null>(null);

  // Función para obtener y actualizar el precio
  const fetchMarketPrice = async () => {
    try {
      const response = await fetch(
        'https://api.paraswap.io/prices/?srcToken=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&destToken=0x22a79a08ddb74A9F1A4eBE5da75300Ad9f1AED76&network=137&partner=quickswapv3&includeDEXS=quickswap%2Cquickswapv3%2Cquickswapv3.1%2Cquickperps&srcDecimals=6&destDecimals=18&amount=1000000&side=SELL&maxImpact=15'
      );
      const data = await response.json();

      if (data && data.priceRoute && data.priceRoute.destAmount) {
        const destAmount = parseFloat(data.priceRoute.destAmount) / 1e18; // Convertir de Wei a AT3
        const srcAmount = parseFloat(data.priceRoute.srcAmount) / 1e6; // USDT
        const marketPrice = srcAmount / destAmount; // USDT/AT3
        setPrice(marketPrice); // Guardar el precio actual
      }
    } catch (error) {
      console.error('Error al obtener el precio de AT3:', error);
    }
  };

  // Actualizar el gráfico cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      fetchMarketPrice();

      if (price !== null) {
        // Actualizar datos del gráfico
        setPriceData((prevData) => [...prevData.slice(-19), price]); // Mantener solo los últimos 20 precios
        setLabels((prevLabels) => [
          ...prevLabels.slice(-19),
          new Date().toLocaleTimeString(), // Agregar la hora actual
        ]);
      }
    }, 4000); // Actualizar cada segundo

    return () => clearInterval(interval); // Limpiar intervalo al desmontar
  }, [price]);

  // Configuración de datos para Chart.js
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Precio AT3/USDT',
        data: priceData,
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: true,
        text: 'Precio en tiempo real de AT3/USDT',
        color: '#ffffff',
      },
    },
    scales: {
      x: {
        ticks: { color: '#ffffff' },
      },
      y: {
        ticks: { color: '#ffffff' },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, backgroundColor: '#051230', p: 3, borderRadius: 2 }}>
      <Typography variant="h6" color="white" align="center" mb={2}>
        1 AT3: {price ? `${price.toFixed(6)} USDT` : 'Cargando...'}
      </Typography>
      <Line data={data} options={options} />
    </Box>
  );
};

export default AT3PriceChart;
