import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ContentMap {
  [key: string]: string;
}

const contentMap: ContentMap = {
  whitepapper: 'https://atomico3.io/whitepaper.pdf',
  
};

const PrivateContent: React.FC = () => {
  const { contentId } = useParams<{ contentId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const contentUrl = contentMap[contentId || ''];
    if (contentUrl) {
      window.location.href = contentUrl; 
    } else {
      navigate('/not-found'); 
    }
  }, [contentId, navigate]);

  return <p>Redirigiendo al contenido...</p>;
};

export default PrivateContent;
