import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Alert, Link } from '@mui/material';
import axios from 'axios';

interface LoginProps {
  onLoginSuccess: () => void;
}

const Login: React.FC<LoginProps> = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  
  const handleLogin = async () => {
    try {
      const response = await axios.post('https://app.atomico3.io/api/v1/login', {
        email,
        password,
      });
      localStorage.setItem('sessionToken', response.data.token);
      onLoginSuccess();
    } catch (err) {
      setError('Error de autenticación, por favor verifica tus credenciales.');
    }
  };

  const handleAnonymousLogin = () => {
    const anonymousToken = 'anonimo_' + Math.random().toString(36).substr(2);
    localStorage.setItem('sessionToken', anonymousToken);
    setAccount(anonymousToken); // Establecer el token en el estado.
    onLoginSuccess();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 400 }}>
      <Typography variant="h6" gutterBottom>Iniciar Sesión</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        label="Correo Electrónico"
        variant="outlined"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Contraseña"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
        Iniciar Sesión
      </Button>

      {/* Botón de acceso anónimo */}
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        <Link
          component="button"
          variant="body2"
          onClick={handleAnonymousLogin}
          sx={{ textTransform: 'none', color: 'gray' }}
        >
          Ingresar como anónimo
        </Link>
      </Typography>
    </Box>
  );
};

export default Login;