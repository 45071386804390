import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import Web3 from 'web3';
import { SelectChangeEvent } from '@mui/material';

const AdminStakingPanel: React.FC = () => {
  const [stakes, setStakes] = useState<any[]>([]);
  const [filteredStakes, setFilteredStakes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [stakesPerPage, setStakesPerPage] = useState<number>(5);

  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_STAKING;
  const contractABI = JSON.parse(process.env.REACT_APP_CONTRACT_ABI || '[]');

 

  const fetchAllStakes = async () => {
    try {
      setLoading(true);
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        const stakingContract = new web3.eth.Contract(contractABI, contractAddress);
  
        const ownerAddress = await stakingContract.methods.owner().call();
        console.log(`Dirección del propietario: ${ownerAddress}`);

        // Llamar al método `getAllStakes` del contrato
        const allStakes = await stakingContract.methods.getAllStakes().call();
  
        // Verificar que sea un arreglo válido
        const validStakes = Array.isArray(allStakes) ? allStakes : [];
  
        const formattedStakes = validStakes.map((stake: any, index: number) => ({
          index,
          address: stake.user || "N/A", // Cambiar según el contrato si no tiene `user`
          amount: web3.utils.fromWei(stake.amount || "0", 'ether'),
          stakingDays: Number(stake.stakingDays || 0),
          startTime: stake.startTime ? new Date(Number(stake.startTime) * 1000) : "N/A",
          reward: web3.utils.fromWei(stake.reward || "0", 'ether'),
          active: !!stake.active,
        }));
  
        setStakes(formattedStakes);
        setFilteredStakes(formattedStakes);
      } else {
        alert('MetaMask no está instalado. Por favor, instálalo para continuar.');
      }
    } catch (error) {
      console.error('Error al obtener los stakes:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllStakes();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchAddress(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
    setCurrentPage(1);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
    setCurrentPage(1);
  };

  const handleStakesPerPageChange = (event: SelectChangeEvent<number>) => {
    setStakesPerPage(Number(event.target.value)); // Convertimos el valor a número
    setCurrentPage(1); // Reiniciar la página a la primera
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const filterStakes = () => {
      const filtered = stakes.filter((stake) => {
        const stakeAddress = stake.address.toLowerCase();
        const stakeStartDate = new Date(stake.startTime);
        const isWithinStartDate = startDate ? stakeStartDate >= new Date(startDate) : true;
        const isWithinEndDate = endDate ? stakeStartDate <= new Date(endDate) : true;

        return (
          stakeAddress.includes(searchAddress) &&
          isWithinStartDate &&
          isWithinEndDate
        );
      });

      setFilteredStakes(filtered);
    };

    filterStakes();
  }, [stakes, searchAddress, startDate, endDate]);

  const indexOfLastStake = currentPage * stakesPerPage;
  const indexOfFirstStake = indexOfLastStake - stakesPerPage;
  const currentStakes = filteredStakes.slice(indexOfFirstStake, indexOfLastStake);

  return (
    <Box p={4}>
      <Typography variant="h5" gutterBottom>
        Administración de Staking
      </Typography>

      <Box display="flex" gap={2} flexWrap="wrap" mb={2}>
        <TextField
          label="Buscar por dirección"
          variant="outlined"
          fullWidth
          value={searchAddress}
          onChange={handleSearchChange}
        />
        <TextField
          label="Desde"
          type="date"
          InputLabelProps={{ shrink: true }}
          fullWidth
          value={startDate}
          onChange={handleStartDateChange}
        />
        <TextField
          label="Hasta"
          type="date"
          InputLabelProps={{ shrink: true }}
          fullWidth
          value={endDate}
          onChange={handleEndDateChange}
        />
        <FormControl fullWidth>
          <InputLabel>Registros por página</InputLabel>
          <Select value={stakesPerPage} onChange={handleStakesPerPageChange}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Dirección</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Días de Staking</TableCell>
                <TableCell>Fecha de Inicio</TableCell>
                <TableCell>Recompensa</TableCell>
                <TableCell>Activo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStakes.map((stake) => (
                <TableRow key={stake.index}>
                  <TableCell>{stake.address}</TableCell>
                  <TableCell>{stake.amount} AT3</TableCell>
                  <TableCell>{stake.stakingDays}</TableCell>
                  <TableCell>{stake.startTime.toLocaleString()}</TableCell>
                  <TableCell>{stake.reward} AT3</TableCell>
                  <TableCell>{stake.active ? 'Sí' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(filteredStakes.length / stakesPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AdminStakingPanel;
